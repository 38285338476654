/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as ReleasesImport } from './routes/releases'
import { Route as NotFoundImport } from './routes/not-found'
import { Route as JsonImport } from './routes/json'
import { Route as DynamicImport } from './routes/_dynamic'
import { Route as IndexImport } from './routes/index'
import { Route as DynamicParamImport } from './routes/dynamic/$param'

// Create/Update Routes

const ReleasesRoute = ReleasesImport.update({
  id: '/releases',
  path: '/releases',
  getParentRoute: () => rootRoute,
} as any)

const NotFoundRoute = NotFoundImport.update({
  id: '/not-found',
  path: '/not-found',
  getParentRoute: () => rootRoute,
} as any)

const JsonRoute = JsonImport.update({
  id: '/json',
  path: '/json',
  getParentRoute: () => rootRoute,
} as any)

const DynamicRoute = DynamicImport.update({
  id: '/_dynamic',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const DynamicParamRoute = DynamicParamImport.update({
  id: '/dynamic/$param',
  path: '/dynamic/$param',
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_dynamic': {
      id: '/_dynamic'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof DynamicImport
      parentRoute: typeof rootRoute
    }
    '/json': {
      id: '/json'
      path: '/json'
      fullPath: '/json'
      preLoaderRoute: typeof JsonImport
      parentRoute: typeof rootRoute
    }
    '/not-found': {
      id: '/not-found'
      path: '/not-found'
      fullPath: '/not-found'
      preLoaderRoute: typeof NotFoundImport
      parentRoute: typeof rootRoute
    }
    '/releases': {
      id: '/releases'
      path: '/releases'
      fullPath: '/releases'
      preLoaderRoute: typeof ReleasesImport
      parentRoute: typeof rootRoute
    }
    '/dynamic/$param': {
      id: '/dynamic/$param'
      path: '/dynamic/$param'
      fullPath: '/dynamic/$param'
      preLoaderRoute: typeof DynamicParamImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof DynamicRoute
  '/json': typeof JsonRoute
  '/not-found': typeof NotFoundRoute
  '/releases': typeof ReleasesRoute
  '/dynamic/$param': typeof DynamicParamRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof DynamicRoute
  '/json': typeof JsonRoute
  '/not-found': typeof NotFoundRoute
  '/releases': typeof ReleasesRoute
  '/dynamic/$param': typeof DynamicParamRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_dynamic': typeof DynamicRoute
  '/json': typeof JsonRoute
  '/not-found': typeof NotFoundRoute
  '/releases': typeof ReleasesRoute
  '/dynamic/$param': typeof DynamicParamRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths: '/' | '' | '/json' | '/not-found' | '/releases' | '/dynamic/$param'
  fileRoutesByTo: FileRoutesByTo
  to: '/' | '' | '/json' | '/not-found' | '/releases' | '/dynamic/$param'
  id:
    | '__root__'
    | '/'
    | '/_dynamic'
    | '/json'
    | '/not-found'
    | '/releases'
    | '/dynamic/$param'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  DynamicRoute: typeof DynamicRoute
  JsonRoute: typeof JsonRoute
  NotFoundRoute: typeof NotFoundRoute
  ReleasesRoute: typeof ReleasesRoute
  DynamicParamRoute: typeof DynamicParamRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  DynamicRoute: DynamicRoute,
  JsonRoute: JsonRoute,
  NotFoundRoute: NotFoundRoute,
  ReleasesRoute: ReleasesRoute,
  DynamicParamRoute: DynamicParamRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_dynamic",
        "/json",
        "/not-found",
        "/releases",
        "/dynamic/$param"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_dynamic": {
      "filePath": "_dynamic.tsx"
    },
    "/json": {
      "filePath": "json.tsx"
    },
    "/not-found": {
      "filePath": "not-found.tsx"
    },
    "/releases": {
      "filePath": "releases.tsx"
    },
    "/dynamic/$param": {
      "filePath": "dynamic/$param.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
