import { Link } from "@tanstack/react-router";

import "../styles/logo.css";

const Logo = () => {
  return (
    <div className="logo">
      <Link to="/" className="logo__link">
        <span className="logo__rough">Rough</span>
        <span className="logo__chuck">Chuck</span>
        <span className="logo__releases">releases</span>
      </Link>
    </div>
  );
};
export default Logo;
