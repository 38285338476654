import React from "react";
import { Link } from "@tanstack/react-router";

import "../styles/nav.css";

const Nav = () => {
  return (
    <nav className="main-nav">
      <Link to="/" className="main-nav__link" exact="true">
        <span>home</span>
      </Link>
      <Link to="/releases" className="main-nav__link">
        <span>releases</span>
      </Link>
    </nav>
  );
};

export default Nav;
