import "./components/styles/header.css";

import Nav from "./components/views/nav";
import Logo from "./components/views/logo";

export default function Header() {
  return (
    <header className="header">
      <div className="container">
        <Logo />
        <Nav />
      </div>
    </header>
  );
}
