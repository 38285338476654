import * as React from "react";
import { Outlet, Link, createRootRoute } from "@tanstack/react-router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { Meta, Scripts } from "@tanstack/start";
import { ClientOnly } from "vite-react-ssg";

import "../styles/core.scss";

import Header from "@/ui/components/Header/Header";

import { NoSSR } from "@/services/NoSSR/Index";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true, // default: true
    },
  },
});

export const Route = createRootRoute({
  component: RootComponent,
  notFoundComponent: () => {
    return (
      <div className="not-found">
        <section className="container">
          <h1>Opps - Not found!!</h1>
        </section>
      </div>
    );
  },
});

function RootComponent() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <div className="">
          <Header />
          <main className="" aria-roledescription="content">
            <Outlet />
          </main>

          <ReactQueryDevtools initialIsOpen={false} />
          <TanStackRouterDevtools position="bottom-left" />
        </div>
      </QueryClientProvider>
      <Scripts />
    </>
  );
}
